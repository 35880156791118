import {
  BrowserRouter, Routes, Route, Navigate,
} from "react-router-dom";

import { Maintenance } from "./Maintenance";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Maintenance />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
