import { FunctionComponent } from "react";
import styled from "styled-components";

// @ts-ignore
import github from "../../public/static/icons/github.svg";
// @ts-ignore
import linkedin from "../../public/static/icons/linkedin.svg";
// @ts-ignore
import instagram from "../../public/static/icons/instagram.svg";
// @ts-ignore
import email from "../../public/static/icons/email.svg";

const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Heading = styled.h1`
  color: #242423;
  font-weight: 300;
  font-size: 3vw;
  text-transform: uppercase;
  line-height: 1.55;
  letter-spacing: 0.23em;
  margin-bottom: 2vw;
`;

const SubHeading = styled.h3`
  margin: 0;
  color: #242423;
  font-weight: 300;
  font-size: 1.6vw;
  line-height: 1;
  letter-spacing: 0.4vw;
  text-transform: uppercase;
`;

const ImageContainer = styled(Container)`
  height: auto;
  margin: 3vw 10vw;
  flex-direction: row;
`;

const Img = styled.img`
  cursor: pointer;
  margin: 0 1vw;
  width: 1.5vw;
`;

const LINKS = {
  EMAIL: "mailto:hrithviksood1@gmail.com",
  LINKEDIN: "https://www.linkedin.com/in/hrithviksood",
  GITHUB: "https://github.com/jacobsood",
  INSTAGRAM: "https://www.instagram.com/hrithvik_sood",
};

function OpenLink(url: string) {
  // @ts-ignore
  window.open(url, "_blank").focus();
}

const Maintenance: FunctionComponent = () => (
  <Container>
    <Heading>Currently in maintenance</Heading>
    <SubHeading>Check back in soon!</SubHeading>
    <ImageContainer>
      <Img src={email} onClick={() => OpenLink(LINKS.EMAIL)} />
      <Img src={linkedin} onClick={() => OpenLink(LINKS.LINKEDIN)} />
      <Img src={github} onClick={() => OpenLink(LINKS.GITHUB)} />
      <Img src={instagram} onClick={() => OpenLink(LINKS.INSTAGRAM)} />
    </ImageContainer>
  </Container>
);

export default Maintenance;
